import './index.css';
import './App.css';
import { Helmet } from "react-helmet"
import * as styles from './Home.module.css'
import logo from '../images/CC-001.png';

import cs from '../images/coming-soon.png';
import entertain from '../images/entertain.png';
import fns_title from '../images/fns_hoodie/fns_title.png';

import junet from '../images/juneteenth.png';
import ida from '../images/ida/idab-demo.png';

import topc from '../images/top-cat.png';
import bottomc from '../images/bottom-cat.png';
import cloud2 from '../images/cloud2.png';

import React, { Component } from "react";
import Nav from '../components/Nav';
import Checkout from '../components/Checkout'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { navigate, Link } from 'gatsby';
import Cart from './cart';

export default class App extends Component {
  constructor() {
    super();

    this.max_quantity = 5

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitNav = this.handleSubmitNav.bind(this);
    this.form = React.createRef();
    this.orders = React.createRef();
    this.display = React.createRef();

    this.productChange = this.productChange.bind(this);

    /* 
    PNG export 50% -> 1500 x 1500 -> https://tinypng.com/
    */
    this.finesse = {
      id: 'hoodie_0001',
      pushid: 'fr_0001',
      name: 'Coming soon',
      desc: 'our very own',
      url: '/fns',
      variant: [
        {
          name: 'FNS',
          img: fns_title
        },
      ],
      size: ['One Size']
    }

    this.juneteenth = {
      id: 'hoodie_0001',
      pushid: 'fr_0001',
      name: 'Juneteenth',
      desc: 'our very own',
      url: '#bottom',
      variant: [
        {
          name: 'Juneteenth',
          img: junet
        },
      ],
      size: ['One Size']
    }

    this.entert = {
      id: 'hoodie_0001',
      pushid: 'fr_0001',
      name: 'Coming soon',
      desc: 'our very own',
      url: '#bottom',
      variant: [
        {
          name: 'Coming Soon',
          img: entertain
        },
      ],
      size: ['One Size']
    }

    this.ida = {
      id: 'hoodie_0001',
      pushid: 'fr_0001',
      name: 'Coming soon',
      desc: 'our very own',
      url: '#bottom',
      variant: [
        {
          name: 'Coming Soon 2',
          img: ida
        },
      ],
      size: ['One Size']
    }

    this.inventory = [
      this.finesse,
      this.juneteenth,
      this.ida,
      this.entert,
    ]


    var products = {}
    for (let product = 0; product < this.inventory.length; product++) {
      products[this.inventory[product].id] = {
        quantity: 0,
        size: this.inventory[product].size[0],
        variant: this.inventory[product].variant[0].name,
        style: this.inventory[product].size[0] + ' - ' + this.inventory[product].variant[0].name,
      }
    }

    this.state = {
      orders: [],
      products,
      productsDisplay: '',
      iterMid: 0,
      iterTop: 0,
      iterMS: 1000,
      bgcolor: this.color[0],
      fgcolor: '#FFFFFF',
    }
  }

  isTopPage() {
    return (window.scrollY < 2);
  }

  isBottomPage() {
    return (window.scrollY > 3800); //mar 13 2022
  }

  nextColor() {

    if (this.isBottomPage())
      return "#000040";

    this.setState({ iterTop: (this.state.iterTop > this.color.length) ? 0 : this.state.iterTop + 1 });

    return this.color[this.state.iterTop];

  }

  nextFgColor() {

    if (this.isBottomPage())
      return "#FFFFFF";

    else return "#000000";
  }

  colorSwatch = () => {

    this.setState({ bgcolor: this.nextColor(), fgcolor: this.nextFgColor() })
  }


  middlecolor = [
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#ffbdbd',
    '#ffbdbd',
    '#ffbdbd',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#FC76AF',
    '#FC76AF',
    '#FC76AF',
    '#FC76AF',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#EE4E34',
    '#ffccbb',
    '#ffccbb',
    '#847ffc',
    '#847ffc',
    '#847ffc',
    '#847ffc',
    '#5c04c5',
    '#5c04c5',
    '#5c04c5',
  ];

  color = [
    '#FFFFFF',
    '#FFFFFF',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#bdc4ff',
    '#c7d4ff',
    '#c7d4ff',
    '#c7d4ff',
    '#c7d4ff',
    '#c7d4ff',
    '#c7d4ff',
    '#DDAAFF',
    '#DDAAFF',
    '#DDAAFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#222222',
    '#222222',
    '#222222',
    '#222222',
    '#222222',
    '#222222',
    '#222222',
    '#222222',
    '#222222']


  handleSubmitNav = (event) => {
    this.handleSubmit();
    this.form.current.submit()
  }

  handleSubmit = (event) => {

    var pushOrder = ''
    var pushDisplay = ''

    if (this.state.orders.length === 0) {
      alert("cart is empty")
      event.preventDefault()
      return;
    }

    for (let i = 0; i < this.state.orders.length; i++) {
      pushOrder = this.state.orders[i].push + ", " + pushOrder;
      pushDisplay = this.state.orders[i].display + ", " + pushDisplay;
    }

    this.orders.current.value = pushOrder
    this.display.current.value = pushDisplay
  }

  addProduct(id, pushid, name, style, quantity) {

    let product = Cart.addProduct(id, pushid, name, style, quantity);

    this.setState({
      orders: [product, ...this.state.orders]
    })
  }

  productChange = (event, product) => {

    let products = Cart.productChange(event, product)

    this.setState({
      products
    })


  }


  componentDidMount() {
    setInterval(this.colorSwatch, this.state.iterMS);
    gsap.registerPlugin(ScrollTrigger)
    gsap.from("#top-logo", {
      duration: 3,
      opacity: 0,
      yPercent: -100
    });


    gsap.from("#topcat", {
      scrollTrigger: {
        trigger: "#topcat",
        scrub: 4, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      opacity: 0,
      yPercent: -100
    });

    const savedCart = window.sessionStorage.getItem('orders')
    if (savedCart != undefined) {
      this.setState({ orders: JSON.parse(savedCart) })
    }
  }

  componentWillUnmount() {
    clearInterval(this.colorSwatch);
  }

  render() {

    const style = {
      backgroundColor: this.state.bgcolor,
      color: this.state.fgcolor
    }
    return (<>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300&display=swap" rel="stylesheet" />
      </Helmet>

      <div>
        <title>COMING SOON | Cozy Club</title>
        <Checkout onClick={this.handleSubmitNav} cart={this.state.orders.length} />
        <div style={style} className={styles.container}>
          <main className={styles.main}>

            <div>
              <img className={styles.cloud3} src={cloud2} alt="" />
              <img className={styles.cloud4} src={cloud2} alt="" />
              <img className={styles.cloud7} src={cloud2} alt="" />
            </div>

            <div id="top-logo" >
              <img className={styles.rogo} src={logo} alt="" width={400} height={400} />

              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

            </div>
            <div id="navbarr" >
              <Nav sticky={true} onClick={this.handleSubmitNav} cart={this.state.orders.length} />
            </div>
            <div id="body" className={styles.description}>
              {Array.from({ length: this.inventory.length }, (value, product) =>
                <div key={product} className={styles.homespacer}>
                  <div>
                    <Carousel onClickItem={() => navigate(this.inventory[product].url)} showThumbs={false} showStatus={false} thumbWidth={120} autoPlay={true} className={styles.homeclip} autoFocus={true} infiniteLoop={true} useKeyboardArrows={true}>
                      {Array.from({ length: this.inventory[product].variant.length }, (value, index) => <img className={styles.blur} key={index} src={this.inventory[product].variant[index].img} alt="" />)}
                    </Carousel>
                  </div>
                  <button onClick={() => navigate(this.inventory[product].url)} className={styles.homecard}>View Collection</button>
                </div>
              )}
            </div>
            <div id="bottom">
              <br /><br /><br />
              <div id="topcat" className={styles.topcat}>
                <img className={styles.topwrapper} src={topc} alt=""></img><br />
              </div>
              <img id="bottomcat" className={styles.wrapper} src={bottomc} alt=""></img>
            </div>
            <form action="https://server.cozyclub.us/create-checkout-session" ref={this.form} onSubmit={this.handleSubmit} method="POST">
              <input type="hidden" name="orders" id="orders" ref={this.orders} />
              <input type="hidden" name="display" id="display" ref={this.display} />

            </form>
            <div className={styles.description}>

              <div id="club" className={styles.title}>
                Cozy Club&trade;
              </div>
              <br />Our mission is to provide
              <br />comfortable garments
              <br />to the community as a whole.
              <br />
              <br />100% Recycled Materials hand chosen for our collections
              <br />
              <br />Fabrics that can be worn inside and out.<br />
              <br />Ask us about our donation program
              <br />
              <br />Do not hesitate to reach out to us!
              <br />
              <br />Homeless shelters + Community outreach + Careers
            </div>
          </main>


          <a
            href="https://instagram.com/cozierclothing"
          >
            <footer className={styles.footer}>
              Cozier Clothing LLC
            </footer>
          </a>

        </div>
      </div>
    </>
    );
  }
}